import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import type {
  AddExternalItemRequest,
  AddZolaSkuRequest,
  CreateCashItemRequest,
  RegistryCollectionItemsSearchRequest,
  WCashRegistryItemViewResponse,
  WRegistryCollectionItemView,
  WRegistryCollectionItemViewResponse,
  WRegistryCollectionSearchView,
  WRegistryView,
} from '@zola/svc-web-api-ts-client';
import ApiService from '../util/apiService';
import Logger from '../util/logger';
import { AppThunk } from './types';
import {
  requestSearch,
  selectCollection,
  receiveSearch,
  requestAddToRegistry,
  receiveAddToRegistry,
} from './types/RegistryCollectionActionTypes';

export const getRegistryProducts =
  ({
    groupedByCollection = false,
    flattenedView = false,
    registryKey,
    filters = {},
    collectionObjectId = null,
    sort = null,
  }: {
    groupedByCollection?: boolean;
    flattenedView?: boolean;
    registryKey: string;
    filters?: Record<string, string[]>;
    collectionObjectId?: string | null;
    sort?: string | null;
  }): AppThunk<Promise<WRegistryCollectionSearchView | undefined>> =>
  (dispatch) => {
    dispatch(requestSearch());
    dispatch(selectCollection(collectionObjectId));
    const request: Omit<RegistryCollectionItemsSearchRequest, 'sort'> & {
      sort?: string;
    } = {
      registry_key: registryKey,
      filters,
      flattened_view: flattenedView,
      collection_object_id: collectionObjectId ?? undefined,
      grouped_by_collection: groupedByCollection,
    };

    if (sort && sort !== 'FEATURED') {
      request.sort = sort;
    }

    return ApiService.post('/web-registry-api/v1/registryCollection/search', request)
      .then((searchView: WRegistryCollectionSearchView) => {
        dispatch(receiveSearch(searchView));
        return searchView;
      })
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: "Sorry we couldn't fetch your registry." }));
        return undefined;
      });
  };

export const getRegistryProductsByRegistryId =
  ({ registryObjectId }: { registryObjectId: string }): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(requestSearch());
    dispatch(selectCollection(registryObjectId));

    const request = {
      registry_object_id: registryObjectId,
      filters: {
        AVAILABILITY: ['AVAILABLE'],
        STORE: ['ZOLA'],
      },
    };
    return ApiService.post('/web-registry-api/v1/registryCollection/search', request)
      .then((searchView: WRegistryCollectionSearchView) => {
        dispatch(receiveSearch(searchView));
      })
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: "Sorry we couldn't fetch your registry." }));
      });
  };

export const addToRegistry =
  (
    data: Omit<AddZolaSkuRequest, 'user_id' | 'platform' | 'registry_id'>,
    productShippingZones?: string[]
  ): AppThunk<Promise<WRegistryCollectionItemView | undefined>> =>
  (dispatch) =>
    ApiService.get('/web-registry-api/v1/registry/user-info').then((resp: WRegistryView) => {
      const fetchedShippingAddressInitials =
        resp && resp.address_view && resp.address_view.state_province_region
          ? resp.address_view.state_province_region
          : 'no address';

      const doesUserLiveInExclusionZone = ['HI', 'AK'].indexOf(fetchedShippingAddressInitials) > -1;
      const isProductInExclusionZone =
        productShippingZones &&
        productShippingZones.length > 0 &&
        productShippingZones.indexOf('US_ALASKA_AND_HAWAII') < 0;
      if (isProductInExclusionZone && doesUserLiveInExclusionZone) {
        const message = 'Product cannot be shipped to Hawaii or Alaska';
        dispatch(toastsActions.negative({ headline: message }));
        return undefined;
      }

      dispatch(requestAddToRegistry());
      return ApiService.post('/web-registry-api/v1/registryCollection/add', data)
        .then((response: WRegistryCollectionItemViewResponse) => {
          dispatch(receiveAddToRegistry(response.data));
          return response.data;
        })
        .catch((err) => {
          dispatch(toastsActions.negative({ headline: err.message }));
          return undefined;
        });
    });

export function addCashFundToRegistry(
  data: Omit<CreateCashItemRequest, 'registry_id' | 'user_object_id' | 'platform'>
): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(requestAddToRegistry());
    return ApiService.post('/web-registry-api/v1/registryCollection/add-cash', data)
      .then((response: WCashRegistryItemViewResponse) => {
        dispatch(receiveAddToRegistry(response.data));
        return undefined;
      })
      .catch((err) => {
        dispatch(toastsActions.negative({ headline: err.message }));
      });
  };
}

export function addExternalItemToRegistry(
  data: Omit<AddExternalItemRequest, 'registry_id' | 'user_object_id'>
): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(requestAddToRegistry());
    return ApiService.post('/web-registry-api/v1/registryCollection/external-product', data).then(
      (response: WRegistryCollectionItemViewResponse) => {
        dispatch(receiveAddToRegistry(response.data));
        return undefined;
      }
    );
  };
}
