import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import type { ConvertWebApiDeclaredToActual } from '@zola-helpers/client/dist/es/@types/svc-web-api';
import type {
  CheckoutViewResponse,
  CreatePaymentIntentRequest,
  WGetCartViewResponse,
  WPaymentIntentView,
} from '@zola/svc-web-api-ts-client';

export type CartResponseType = CamelCasedPropertiesDeep<
  ConvertWebApiDeclaredToActual<WGetCartViewResponse>
>;
export type CartDetailsResponseType = CamelCasedPropertiesDeep<
  ConvertWebApiDeclaredToActual<CheckoutViewResponse>
>;

export type PaymentIntentView = CamelCasedPropertiesDeep<
  ConvertWebApiDeclaredToActual<WPaymentIntentView>
>;

export const fetchCart = () => {
  return ApiService.get<
    CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<WGetCartViewResponse>>
  >('/web-registry-api/v1/cart');
};

export const fetchInitialCartDetails = () => {
  return ApiService.get<
    CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<CheckoutViewResponse>>
  >('/web-registry-api/v1/checkout');
};

export type CreatePaymentIntentBody = CamelCasedPropertiesDeep<
  Omit<CreatePaymentIntentRequest, 'ip_address' | 'user_agent'>
>;

export const createPaymentIntent = (body: CreatePaymentIntentRequest) => {
  return ApiService.post<
    PaymentIntentView,
    CamelCasedPropertiesDeep<Omit<CreatePaymentIntentRequest, 'ip_address' | 'user_agent'>>
  >('/web-registry-api/v1/checkout/create-payment-intent', body);
};
