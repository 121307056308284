import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Swatch.less';

export default function Swatch({
  imageUrl,
  color,
  label,
  id,
  isActive,
  handleSwatchChange,
  size,
  isInteractive,
}) {
  const classnames = classNames(
    'swatch',
    { 'is-active': isActive },
    { 'is-interactive': isInteractive },
    size
  );

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSwatchChange(id);
  };
  const handleClick = isInteractive ? onClick : null;

  return (
    <span key={id} className={classnames} onClick={handleClick}>
      {imageUrl && <img src={imageUrl} title={label} alt={label} />}
      {color && <span className={`swatch__inner ${color}`} />}
    </span>
  );
}

Swatch.propTypes = {
  imageUrl: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isInteractive: PropTypes.bool,
  handleSwatchChange: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
};
